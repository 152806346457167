let ambient = "";
// ambient = "Dev";
//  ambient = "Dev";
ambient = "Prod";

let api_route_centralizado = "";
let api_route_centralizadoNuevo = "";

switch (ambient) {
    case 'Dev':
        api_route_centralizado = "http://10.161.252.87:3010/Autenticacion_Des/";
        api_route_centralizadoNuevo = "http://10.161.252.87/MCP/DEV/";

        break;
    case 'QA':
        // api_route_centralizado = "http://10.161.252.108:17801/";
        api_route_centralizado = "http://10.161.252.87:3010/Autenticacion_Cap/";
        api_route_centralizadoNuevo = "http://10.161.252.87/MCP/UAT/";

        break;
    case 'Prod':
        // api_route_centralizado = "http://centralizado_api.instacredit.co.cr:17801/";
        // api_route_centralizado = "https://centralizado_api.instacredit.com:17801/";
        api_route_centralizado = "https://portal.instacredit.com/centralizado/";
        api_route_centralizadoNuevo = "https://portal.instacredit.com/WS_ModuloPermisos/";
        break;
}

export default {

    API: { 
        Auth: {
            login_local:                                "localhost:4000",
            ApiAutenticacion_Autorizacion:              api_route_centralizado + "ApiAutenticacion_Autorizacion/FN",
            ApiAutenticacion_Validacion:                api_route_centralizado + "ApiAutenticacion_Validacion/FN",
            ApiAutenticacion_Usuario_Listar:            api_route_centralizado + "ApiAutenticacion_Usuario_Listar/FN",
            ApiAutenticacion_Usuario_Obtener:           api_route_centralizado + "ApiAutenticacion_Usuario_Obtener/FN",
            ApiAutenticacion_Usuario_Actualizar:        api_route_centralizado + "ApiAutenticacion_Usuario_Actualizar/FN",
            ApiAutenticacion_Listar_Pais_Empresa:       api_route_centralizado + "ApiAutenticacion_Inicio/MenuPae_Listar",
            ApiAutenticacion_Nuevo:                     api_route_centralizadoNuevo + "API_AUTENTICACION/Autenticacion/Autenticar",
        },
        Permisos: {
            local:                                      "localhost:4000",
            ApiAutenticacion_Permisos_Listar:           api_route_centralizado + "ApiAutenticacion_Permisos_Listar/FN",
            ApiAutenticacion_Permisos_Obtener:          api_route_centralizado + "ApiAutenticacion_Permisos_Obtener/FN",
            ApiAutenticacion_Permisos_Actualizar:       api_route_centralizado + "ApiAutenticacion_Permisos_Actualizar/FN",
        },
        Roles: {
            ApiAutenticacion_Rol_Listar:                api_route_centralizado + "ApiAutenticacion_Rol_Listar/FN",
            ApiAutenticacion_Rol_Obtener:               api_route_centralizado + "ApiAutenticacion_Rol_Obtener/FN",
            ApiAutenticacion_Rol_Actualizar:            api_route_centralizado + "ApiAutenticacion_Rol_Actualizar/FN",
            
        },
        RolesPorUsuario: {
            ApiAutenticacion_RolesPorUsuario_Listar:    api_route_centralizado + "ApiAutenticacion_Usuario_X_Rol_Listar/FN",
            ApiAutenticacion_RolesPorUsuario_Obtener:   api_route_centralizado + "ApiAutenticacion_Usuario_X_Rol_Obtener/FN",
            ApiAutenticacion_RolesPorUsuario_Actualizar:api_route_centralizado + "ApiAutenticacion_Usuario_X_Rol_Actualizar/FN",
            ApiAutenticacion_RolesPorUsuario_Eliminar:  api_route_centralizado + "ApiAutenticacion_Usuario_X_Rol_Eliminar/FN",
        },
        Pais: {
            ApiAutenticacion_Pais_Listar:                api_route_centralizado + "ApiAutenticacion_Pais_Listar/FN",
            ApiAutenticacion_Pais_Obtener:               api_route_centralizado + "ApiAutenticacion_Pais_Obtener/FN",
            ApiAutenticacion_Pais_Actualizar:            api_route_centralizado + "ApiAutenticacion_Pais_Actualizar/FN",
            ApiAutenticacion_Pais_Eliminar:              api_route_centralizado + "ApiAutenticacion_Pais_Eliminar/FN",
        },
        Pais_Empresa: {
            ApiAutenticacion_Pais_Empresa_Listar:       api_route_centralizado + "ApiAutenticacion_Pais_Empresa_Listar/FN",
            ApiAutenticacion_Pais_Empresa_Obtener:      api_route_centralizado + "ApiAutenticacion_Pais_Empresa_Obtener/FN",
            ApiAutenticacion_Pais_Empresa_Actualizar:   api_route_centralizado + "ApiAutenticacion_Pais_Empresa_Actualizar/FN",
            ApiAutenticacion_Pais_Empresa_Eliminar:     api_route_centralizado + "ApiAutenticacion_Pais_Empresa_Eliminar/FN",
        },
        Sistema: {
            ApiAutenticacion_Sistema_Listar:            api_route_centralizado + "ApiAutenticacion_Sistema_Listar/FN",
            ApiAutenticacion_Sistema_Obtener:           api_route_centralizado + "ApiAutenticacion_Sistema_Obtener/FN",
            ApiAutenticacion_Sistema_Actualizar:        api_route_centralizado + "ApiAutenticacion_Sistema_Actualizar/FN",
            ApiAutenticacion_Sistema_Eliminar:          api_route_centralizado + "ApiAutenticacion_Sistema_Eliminar/FN",
            ApiAutenticacion_Sistema_X_Usuario:         api_route_centralizado + "ApiAutenticacion_Sistema_X_Usuario/ApiAutenticacion_Sistemas_X_Usuario"
        },
        Empresa: {
            ApiAutenticacion_Empresa_Listar:            api_route_centralizado + "ApiAutenticacion_Empresa_Listar/FN",
            ApiAutenticacion_Empresa_Obtener:           api_route_centralizado + "ApiAutenticacion_Empresa_Obtener/FN",
            ApiAutenticacion_Empresa_Actualizar:        api_route_centralizado + "ApiAutenticacion_Empresa_Actualizar/FN",
            ApiAutenticacion_Empresa_Eliminar:          api_route_centralizado + "ApiAutenticacion_Empresa_Eliminar/FN",
        },

        Permisos_X_Rol:{
            ApiAutenticacion_Permisos_X_Rol_Listar:     api_route_centralizado + "ApiAutenticacion_Permisos_X_Rol_Listar/FN",
            ApiAutenticacion_Permisos_X_Rol_Obtener:    api_route_centralizado + "ApiAutenticacion_Permisos_X_Rol_Obtener/FN",
            ApiAutenticacion_Permisos_X_Rol_Actualizar: api_route_centralizado + "ApiAutenticacion_Permisos_X_Rol_Actualizar/FN",
        }, 
        LinkSistemas: {
            Sistema_RecursosHumanos:                    "http://10.161.252.108:3001/",
        },

        PermisoSistemaPagos:{
            ApiUsuarios_ConsultaRol:                         api_route_centralizadoNuevo + "API_USUARIOS/api/Usuarios/ConsultaRol",
            
        }

    }
}