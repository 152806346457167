import React from "react";
import { Button } from "react-bootstrap";
import "../../styles/scss/pages/_cards.scss";

//Banderas
import banderaCostaRica from "../../styles/assets/img/bandera-de-costa-rica.jpg";
import banderaNicaragua from "../../styles/assets/img/Flag_of_Nicaragua.svg.png";
import banderaPanama from "../../styles/assets/img/Bandera de Panama.jpg";
import banderaElSalvador from "../../styles/assets/img/Flag_of_Nicaragua.svg.png";
import prestamito from "../../styles/assets/img/prestamito.jpg";

// function Card({ imageSource, title, text, url }) {
function CardComponent( params ) {

  let bandera;

  //Paises en mayusculas por standard de como se guardan en la base de datos
  if( params.imagenPais == "COSTA RICA"){

    bandera = banderaCostaRica;

  } else if ( params.imagenPais == "NICARAGUA" ) {
    bandera = banderaNicaragua;
  } else if ( params.imagenPais == "PANAMA" ) {
    bandera = banderaPanama;
  } else if ( params.imagenPais == "EL SALVADOR" ) {
    bandera = banderaElSalvador;
  } else {
    bandera = prestamito;
  }

  // const funcion = params.recibirId();

    return (
      <div className="card text-center bg-dark animate__animated animate__fadeInUp mb-4 mx-4 rounded-3">
        <div className="overflow rounded-3">
          <img src={ bandera} alt="bandera" className="card-img-top" />
        </div>
        <div className="card-body text-light rounded-3">
          <h4 className="card-title">{params.NombreEmpresa} </h4>
          <Button className="btn" onClick={ () => params.funcion(params.id_Pais_Sistema)  }>Seleccionar</Button>
        </div>
      </div>
    );
}


export default CardComponent;
