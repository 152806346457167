import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Carousel, Card, Button } from "react-bootstrap";
import { useSistema } from "../../hooks/useSistema";
import services from "../../services/servicesTeso";
import logo from "../../styles/assets/img/logo.png";
import FondoPrincipal from "../../styles/assets/img/FondoPrincipal.png";
import { usePrincipalStore } from "../../hooks/usePrincipalStore";

//Spinner
import { useAuthStore } from "../../hooks";
import SpinnerCarga from "../../sharedComponents/SpinnerCarga";

import "../../styles/Principal.css";



export default function Standard() {

  const { estadoCargando } = useAuthStore();

  const { envioIdSistema } = usePrincipalStore();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { DataSelectSistema } = useSistema();
  const { sistemas } = useSelector((state) => state.sistemaRedux);


  const handleCardClick = (index) => {
    setCurrentSlide(index);
  };

  const totalSlides = sistemas.length;

  useEffect(() => {
    DataSelectSistema();

    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = (data) => {

   

    const token = localStorage.getItem("token");
    const user = localStorage.getItem("payload");
    const url = `${data}?start=${encodeURIComponent(
      token
    )}&user=${encodeURIComponent(user)}`;
    window.open(url, "_blank");

  };

  const handleClickPagos = (data) => {


    const token = localStorage.getItem("token2");
    const url = `${data}?token=${encodeURIComponent(
      token
    )}`;
    window.open(url, "_blank");

  };

  //Validación para mostrar el spinner
  if ( estadoCargando === true ) {
    return (
      
    <SpinnerCarga title="Cargando lista de Sistemas..." />
  
  )
  }

  return (
    <React.Fragment>
      {/* <Header /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <img
              src={logo}
              alt="Logo de Instacredit"
              style={{ margin: "auto", width: "200px", height: "auto" }}
            />
          </div>
          {/* <Button
            variant="primary"
            className="button-support"
            as={Link}
            style={{
              fontSize: "1rem",
              backgroundColor: "white",
              borderColor: "white",
              borderRadius: "40px",
              height: "30px",
              color: "darkgreen",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Soporte
          </Button> */}
        </div>
          <Card.Body>
            <Carousel slide={true}>
              {sistemas.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={`data:image/png;base64, ${item.sis_imagen_principal}`}
                      alt={item.nombre}
                      style={{
                        margin: "auto",
                        width: "auto",
                        height: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>

            <div
              className="d-flex justify-content-center card-wrapper"
              style={{ flexWrap: "wrap" }}
            >
                { sistemas.map((item, index) => {
                    return (
                        <Card
                            key={index}
                            className="custom-card overlay-card"
                            onClick={() => 
                                item.sis_id === 999 
                                ? handleClickPagos(item.sis_link) 
                                : item.sis_id !== 3 
                                    ? envioIdSistema(item.sis_id, item.sis_link) 
                                    : handleClick(item.sis_link)
                            }
                            style={{
                                width: "16rem",
                                margin: "10px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
                                borderRadius: "10px",
                            }}
                        >
                            <Card.Body className="text-center">
                                <Card.Title></Card.Title>
                                <div className="card-image-wrapper img-container">
                                    <Card.Img
                                        className="img"
                                        variant="bottom"
                                        src={`data:image/png;base64, ${item.sis_imagen_card}`}
                                        alt="Imagen"
                                        style={{ width: "50%", margin: "auto" }}
                                    />
                                </div>
                                <Card.Text className="card-text">
                                    {item.sis_nombre}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    );
                })}

            </div>
          </Card.Body>
      
      </div>
      {/* <Footer /> */}
    </React.Fragment>
  );
}
